import { createMuiTheme } from '@material-ui/core/styles';

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    // primary: indigo,
    // secondary: lightBlue,
  },
});

export default theme;
