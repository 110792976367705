import * as React from "react"

type EventListener = (data?: any) => void

export default class EventBus {
  private listeners: {[event: string]: EventListener[]} = {}

  on (event: string, listener: EventListener) {
    if (!this.listeners[event]) {
      this.listeners[event] = []
    }

    this.listeners[event].push(listener)
  }

  dispatch (event: string, data?: any) {
    if (this.listeners[event]) {
      this.listeners[event].forEach(l => l(data))
    }
  }

  remove (listener: EventListener) {
    for (const e in this.listeners) {
      this.listeners[e] = this.listeners[e].filter(l => l !== listener)
    }
  }
}

export type EventBusContextType = {
  eventBus: EventBus
}

const EventBusContext = React.createContext<EventBusContextType>({ eventBus: new EventBus() })

const globalEventBus = new EventBus()

export {
  EventBusContext,
  globalEventBus,
}
