import React from 'react'
import { observer } from 'mobx-react'
import { RouteComponentProps } from 'react-router'
import AuthStore from '../stores/AuthStore'
import { Redirect } from "react-router-dom"
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'

type Props = {
  match: {
    params: {
      authToken: string
    }
  }
} & RouteComponentProps

@observer
export default class LandingView extends React.Component<Props> {
  componentDidMount () {
    AuthStore.setAuthToken(this.props.match.params.authToken)
  }

  componentDidUpdate (prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    if (prevProps.match.params.authToken !== this.props.match.params.authToken) {
      if (this.props.match.params.authToken) {
        AuthStore.setAuthToken(this.props.match.params.authToken)
      } else {
        AuthStore.clearAuthToken()
      }
    }
  }

  render () {
    return <Redirect to={route(Routes.index)}/>
  }
}
