import { action, computed, observable } from "mobx"
import Cookies from "universal-cookie"
import AuthUser from '../models/AuthUser'

const cookies = new Cookies()

class AuthStore {
  private AUTH_TOKEN_STORAGE_KEY = 'letip_wired_ext:auth_token'

  @observable private authToken?: string
  @observable private authUser?: AuthUser

  @computed get hasAuthToken (): boolean {
    return !!this.authToken
  }

  @action
  public setAuthUser (authUser: AuthUser) {
    this.authUser = authUser
  }

  @action
  public clearAuthUser () {
    this.authUser = undefined
  }

  public getAuthTokenId (): string | null {
    return this.getAuthToken()
      ? this.getAuthToken()!.split(':')[0]
      : null
  }

  public getAuthToken (): string | undefined {
    return this.authToken
  }

  public getAuthUser () {
    return this.authUser
  }

  @action
  public clearAuthToken () {
    if (this.authToken) {
      this.authToken = undefined
      cookies.remove(this.AUTH_TOKEN_STORAGE_KEY, { path: '/' })
      localStorage.removeItem(this.AUTH_TOKEN_STORAGE_KEY)
    }
  }

  @action
  public setAuthToken (authToken: string) {
    if (this.authToken !== authToken) {
      this.authToken = authToken
      cookies.set(this.AUTH_TOKEN_STORAGE_KEY, authToken, { path: '/' })
    }
  }

  @action
  public restoreAuthToken () {
    if (!this.authToken) {
      this.authToken = cookies.get(this.AUTH_TOKEN_STORAGE_KEY) || localStorage.getItem(this.AUTH_TOKEN_STORAGE_KEY) || undefined
    }
  }
}

const auth = new AuthStore()

export default auth as AuthStore
