import React, { FormEvent } from 'react'
import { AppBar, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, Grid, IconButton, Slide, TextField, Toolbar, Typography } from '@material-ui/core'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { TransitionProps } from '@material-ui/core/transitions'
import CloseIcon from '@material-ui/icons/Close'
import Category from '../models/Category'
import HelpTooltip from './HelpTooltip'
import { Alert } from '@material-ui/lab'
import FormState from '../common/FormState'
import ErrorBag from '../common/ErrorBag'
import FormHelper from '../common/FormHelper'
import ApiClient, { apiRoute, ApiRoutes } from '../api/ApiClient'
import { handleErrorResponse, modelToSnakeCase, scrollToFormErrors } from '../common/Util'
import { withSnackbar, WithSnackbarProps } from 'notistack'

type Props = {
  onExited: () => void
  category: Category
  onSubmitted?: () => void
} & WithSnackbarProps

const Transition = React.forwardRef(function Transition (
  props: TransitionProps & {children?: React.ReactElement},
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
})

@observer
class TipFormDialog extends React.Component<Props> {
  @observable private isOpen = true
  @observable submitting = false

  @observable private formState = new FormState({
    name: '',
    companyName: '',
    phone: '',
    email: '',
    tipDetails: '',
  })

  @observable private formError?: React.ReactNode
  @observable private showFormErrorModal = false
  @observable private formErrors = new ErrorBag()

  private formHelper = new FormHelper(this.formState, this.formErrors)

  private handleDialogClose = () => {
    this.isOpen = false
  }

  private submit = (ev: FormEvent) => {
    ev.preventDefault()

    if (this.submitting) {
      return
    }

    this.submitting = true
    this.formErrors.clearErrors()

    ApiClient.getInstance()
      .post(apiRoute(ApiRoutes.submitTip), modelToSnakeCase({
        ...this.formState.toObject(),
        targetMemberId: this.props.category.member.id,
      }))
      .then(response => {
        this.isOpen = false
        this.props.enqueueSnackbar('Your tip has been submitted', {
          variant: 'success',
        })
        this.props.onSubmitted && this.props.onSubmitted()
      })
      .catch(error => {
        const errors = new ErrorBag()
        handleErrorResponse(error.response, errors, undefined, (response, message) => {
          this.formError = message
          this.showFormErrorModal = true
          return true
        })

        this.formErrors.addErrors(errors.getErrorList())
        if (this.formErrors.hasErrors()) {
          scrollToFormErrors(this.formErrors, errors => {
            this.formError = errors.map(e => <div>{e}</div>)
            this.showFormErrorModal = true
          })
        }
      })
      .then(() => {
        this.submitting = false
      })
  }

  render () {
    return <Dialog
      fullScreen
      open={this.isOpen}
      onExited={this.props.onExited}
      onClose={this.handleDialogClose}
      TransitionComponent={Transition}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={this.handleDialogClose} aria-label="close" disabled={this.submitting}>
            <CloseIcon/>
          </IconButton>
          <Typography variant="h6">Send a Tip</Typography>
        </Toolbar>
      </AppBar>
      <Container maxWidth="sm" style={{ marginTop: 30 }}>
        <Typography style={{ fontWeight: 'bold', marginBottom: 4 }}>Please reach out to me</Typography>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ overflow: 'hidden', fontSize: 10 }}>
            <img alt={this.props.category.member.fullName} src={this.props.category.member.profileImageUrl || this.props.category.logoUrl || '/img/user-profile-placeholder.png'} style={{ width: 80, height: 80, objectFit: 'cover', objectPosition: 'top center', backgroundColor: '#ccc' }}/>
          </div>
          <div style={{ marginLeft: 10 }}>
            <Typography>{this.props.category.member.fullName}</Typography>
            <Typography style={{ color: '#666' }}>{this.props.category.name}</Typography>
          </div>
        </div>
        <Alert color="info" style={{ marginTop: 20 }}>
          I'm searching for a solution provider, not just information.
        </Alert>
        <form onSubmit={this.submit} style={{ marginTop: 30 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Person To Contact Name"
                autoFocus
                name="name"
                {...this.formHelper.applyProps('name')}
                disabled={this.submitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Company Name"
                {...this.formHelper.applyProps('companyName')}
                name="companyName"
                disabled={this.submitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                type="tel"
                fullWidth
                label="Phone Number"
                required
                {...this.formHelper.applyProps('phone')}
                name="phone"
                disabled={this.submitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                type="email"
                label="Email Address"
                {...this.formHelper.applyProps('email')}
                name="email"
                disabled={this.submitting}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                label="Details"
                placeholder="Tell me what you need"
                required
                fullWidth
                multiline={true}
                {...this.formHelper.applyProps('tipDetails')}
                name="tipDetails"
                disabled={this.submitting}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              onClick={this.handleDialogClose}
              color="secondary"
              disabled={this.submitting}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={this.submitting}
            >
              Submit
            </Button>
          </DialogActions>
        </form>
      </Container>
      {
        this.formError
          ? <Dialog
            open={this.showFormErrorModal}
            onClose={() => this.showFormErrorModal = false}
            onExited={() => this.formError = undefined}
          >
            <DialogTitle>Error</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {this.formError}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => this.showFormErrorModal = false} color="primary">
                OK
              </Button>
            </DialogActions>
          </Dialog>
          : null
      }
    </Dialog>
  }
}

export default withSnackbar(TipFormDialog)
