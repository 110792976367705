import axios, { AxiosRequestConfig } from 'axios'
import Config from "../common/Config"
import { compileRoutes } from "../routes/routes"
import { trimLeft, trimRight } from "../common/Util"
import * as qs from "qs"
import AuthStore from '../stores/AuthStore'

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
axios.defaults.headers.post['Accept'] = 'application/json'

const AUTH_TOKEN_HEADER_KEY = 'X-AUTH-TOKEN'

export type QueryWhereClause = {id: string, value: any} | {[column: string]: any} | {id: string, op: string, value: any} | {_scope: string, value?: any} | undefined
export type QueryOrderClause = {id: string, desc?: boolean} | string

export interface QueryOptions {
  where?: QueryWhereClause[]
  order?: QueryOrderClause[]
  offset?: number
  limit?: number
  returnTotal?: boolean
}

class ApiClient {
  getInstance = () => {
    let params = {
      maxContentLength: 10000,
      timeout: 30000,
    }

    const headers = {
      [AUTH_TOKEN_HEADER_KEY]: AuthStore.getAuthToken() || null,
      'X-API-VERSION': Config.API_VERSION,
    }

    return axios.create({
      ...params,
      baseURL: Config.API_URL,
      headers: headers,
    })
  }

  query = (query: string, options: QueryOptions = {}, requestOptions: AxiosRequestConfig = {}) => {
    let { where, order, ...otherOptions } = options
    where = where ? where.filter(w => !!w) : undefined
    order = order ? order.filter(s => !!s) : undefined
    const queryObject = {
      ...otherOptions,
      where: where,
      order: order,
      query: query,
    }

    const config = Object.assign({}, { headers: { 'Content-Type': 'application/json' } }, requestOptions)

    return this.getInstance()
      .post('/query', JSON.stringify(queryObject), config)
  }
}

export function apiRoute (path: string, query: {} = {}) {
  let url = [trimRight(Config.API_URL || '', ['/']), trimLeft(path, ['/'])].join('/')

  if (Object.getOwnPropertyNames(query).length > 0) {
    url = `${url}${url.indexOf('?') === -1 ? '?' : '&'}${qs.stringify(query)}`
  }

  return url
}

export const ApiRoutes = {
  profile: '/profile',
  categories: '/categories',
  category: '/category/:id',
  submitTip: '/submit-tip',
}

compileRoutes(ApiRoutes)

export default new ApiClient()
