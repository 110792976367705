import { textval } from "./Util"
import * as version from "./version.json"

class Config {
  NODE_ENV?: string = process.env.NODE_ENV
  ENV?: string = process.env.REACT_APP_ENV
  API_URL?: string = process.env.REACT_APP_API_URL
  SENTRY_DSN?: string = process.env.REACT_APP_SENTRY_DSN
  SENTRY_DISABLE_CAPTURE: boolean = !!textval(process.env.REACT_APP_SENTRY_DISABLE_CAPTURE)
  GOOGLE_ANALYTICS_TRACKING_ID?: string = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
  API_VERSION: number = 1
  VERSION: string = version.version
  BUILD_NUMBER: number = version.buildNumber
}

const config = new Config()

export default config as Config
