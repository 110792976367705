import React from 'react'
import Config from '../common/Config'

export default class AppVersion extends React.Component {
  render () {
    return <div className="app-version">
      Version {Config.VERSION || '???'}{Config.BUILD_NUMBER ? `-${Config.BUILD_NUMBER}` : null} {Config.ENV !== 'production' ? `(${Config.ENV})` : null} {Config.NODE_ENV !== 'production' ? `[${Config.NODE_ENV}]` : null}
    </div>
  }
}
