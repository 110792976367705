import moment from 'moment'
import { AxiosResponse } from 'axios'
import _ from 'lodash'
import ErrorBag from './ErrorBag'

export function textval (v: any) {
  switch (v) {
    case 'true':
      return true
    case 'false':
      return false
    case 'null':
      return null
    default:
      return v
  }
}

export function transformIf (v: any, t: (a: any) => any) {
  return v ? t(v) : v
}

export function trimLeft (str: string, chars: string[]): string {
  while (str.length && chars.indexOf(str.charAt(0)) > -1) {
    str = str.substr(1)
  }

  return str
}

export function trimRight (str: string, chars: string[]): string {
  while (str.length && chars.indexOf(str.charAt(str.length - 1)) > -1) {
    str = str.substr(0, str.length - 1)
  }

  return str
}

export function parseDate (date: string) {
  return date ? moment(date) : date
}

export function extractErrorMessage (response: AxiosResponse | any) {
  if (response && response.response) {
    response = response.response
  }

  return ((response && response.data)
    ? (response.data.error || response.data.message)
    : undefined)
    || 'A server error has occurred'
}

export function safeNull (resolve: () => any) {
  try {
    return resolve()
  } catch (err) {
    if (err instanceof TypeError) {
      return undefined
    } else {
      throw err
    }
  }
}

export function modelToSnakeCase (d: {}) {
  const out = {}
  Object.getOwnPropertyNames(d).forEach(k => {
    if (_.isArray(d[k])) {
      out[_.snakeCase(k)] = _.map(d[k], v => _.isObject(v) ? modelToSnakeCase(v) : v)
    } else if (_.isObject(d[k])) {
      out[_.snakeCase(k)] = modelToSnakeCase(d[k])
    } else {
      out[_.snakeCase(k)] = d[k]
    }
  })
  return out
}

export function modelToCamelCase (d: {}) {
  const out = {}
  Object.getOwnPropertyNames(d).forEach(k => {
    if (_.isArray(d[k])) {
      out[_.camelCase(k)] = _.map(d[k], v => _.isObject(v) ? modelToCamelCase(v) : v)
    } else if (_.isObject(d[k])) {
      out[_.camelCase(k)] = modelToCamelCase(d[k])
    } else {
      out[_.camelCase(k)] = d[k]
    }
  })
  return out
}

export function handleErrorResponse (response: AxiosResponse, errorBag: ErrorBag | null = null, handler: (response: AxiosResponse) => boolean = () => false, modalErrorHandler: (response: AxiosResponse, message: string) => boolean = () => false) {
  if (response && response.status === 422) {
    if (response.data.errors) {
      if (errorBag) {
        errorBag.addErrors(response.data.errors)
      } else {
        if (!modalErrorHandler(response, new ErrorBag().addErrors(response.data.errors).getErrors().join(', '))) {
        }
      }
    } else {
      if (!modalErrorHandler(response, response.data.error || response.data.message || 'A server error has occurred')) {
      }
    }
  } else if (response && response.status === 429) {
    if (!handler(response) && !modalErrorHandler(response, 'You are making too many requests. Please wait a few minutes and try again.')) {
    }
  } else if (response && response.status === 403) {
    if (!handler(response) && !modalErrorHandler(response, 'You do not have permission to perform this action')) {
    }
  } else {
    if (!handler(response) && !modalErrorHandler(response, 'A server error has occurred')) {
    }
  }
}

export function scrollToFormErrors (formErrors: ErrorBag, modalErrorHandler: (messages: string[]) => void) {
  setTimeout(() => {
    const el: HTMLCollectionOf<Element> = document.getElementsByClassName('Mui-error')

    if (el.length) {
      el[0].scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
      // (container || window).scroll({ left: 0, top: (container ? container.scrollTop : window.scrollY) + el[0].getBoundingClientRect().top - 100, behavior: 'smooth' })
    } else if (formErrors.hasErrors()) {
      // there are no visible errors on the form, but we have errors. show them in a modal
      modalErrorHandler(formErrors.getErrors())
    }
  }, 100)
}
