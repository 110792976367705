import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/core/styles';
import App from './App';
import theme from './theme';
import * as serviceWorker from './serviceWorker'
import Config from './common/Config'
import * as Sentry from '@sentry/browser'
import { SnackbarProvider } from 'notistack'
import { globalEventBus } from './common/EventBus'
import { route } from './routes/routes'
import { Routes } from './routes/AppRoutes'


if (Config.SENTRY_DSN && !Config.SENTRY_DISABLE_CAPTURE) {
  Sentry.init({
    dsn: Config.SENTRY_DSN,
  })
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
    <CssBaseline/>
    <SnackbarProvider maxSnack={3}>
      <App/>
    </SnackbarProvider>
  </ThemeProvider>,
  document.querySelector('#root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
  onUpdate: (registration) => {
    if (registration.waiting) {
      const waiting = registration.waiting
      const reload = () => {
        waiting.postMessage({ type: 'SKIP_WAITING' })
        setTimeout(() => window.location.href = route(Routes.index), 250)
      }

      globalEventBus.dispatch('newVersionAvailable', {
        reload,
      })
    }
  }
})



