export enum AuthUserStatus {
  Invited = 'Invited',
  Active = 'Active',
  Revoked = 'Revoked',
}

type InvitingMember = {
  memberId: number
  fullName: string
  profileImageUrl: string | null
}

export default class AuthUser {
  constructor (public name: string, public status: AuthUserStatus, public invitingMember: InvitingMember) {
  }
}
