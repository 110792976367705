import BaseModel from './BaseModel'

export type VideoData = {
  id: string
  type: string
}

export default class Category extends BaseModel {
  id: number
  name: string
  logoUrl: string

  business : {
    name: string
    description: string
  }

  member: {
    id: number
    fullName: string
    profileImageUrl: string
    videoData: VideoData
  }

  getCasts (): {} {
    return {
      id: 'number',
    }
  }
}
