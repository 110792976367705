import React from "react";
import { Redirect, Route } from "react-router-dom";
import AuthStore from '../stores/AuthStore'
import { AuthUserStatus } from '../models/AuthUser'

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={(props) => {
    const authUser = AuthStore.getAuthUser()

    return !authUser
      ? null
      : authUser.status === AuthUserStatus.Revoked
        ? <Redirect to='/revoked'/>
        : <Component {...props} />
  }}
  />
)
