import React from 'react'
import { observer } from 'mobx-react'
import { Button, IconButton, LinearProgress, Paper } from '@material-ui/core'
import { observable } from 'mobx'
import Category from '../models/Category'
import TipFormDialog from '../components/TipFormDialog'
import ApiClient, { apiRoute, ApiRoutes } from '../api/ApiClient'
import { extractErrorMessage, modelToCamelCase } from '../common/Util'
import { route } from '../routes/routes'
import { RouteComponentProps } from 'react-router'
import { Alert, AlertTitle } from '@material-ui/lab'
import AppStateStore from '../stores/AppStateStore'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { createBrowserHistory } from "history"
import VideoPlayer from '../components/VideoPlayer'

type Props = {
  match: {
    params: {
      id: string
    }
  }
} & RouteComponentProps

@observer
export default class CategoryView extends React.Component<Props> {
  @observable private showTipForm = false
  @observable private loading = false
  @observable private category?: Category
  @observable private error?: string

  private history = createBrowserHistory()

  componentDidMount (): void {
    AppStateStore.headerBackButton = <IconButton onClick={() => this.history.goBack()}>
      <ArrowBackIcon style={{ color: '#fff' }}/>
    </IconButton>

    this.loading = true
    ApiClient.getInstance().get(apiRoute(route(ApiRoutes.category, { id: this.props.match.params.id })))
      .then(response => {
        this.category = new Category().init(modelToCamelCase(response.data.category))
      })
      .catch(error => {
        this.error = extractErrorMessage(error)
      })
      .then(() => {
        this.loading = false
      })

    window.scrollTo(0, 0)
  }

  componentWillUnmount (): void {
    AppStateStore.headerBackButton = undefined
  }

  render () {
    return this.error
      ? <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        {this.error}
      </Alert>
      : (this.loading || !this.category)
        ? <LinearProgress/>
        : <>
          <Paper style={{ padding: 20 }}>
            <div style={{ display: 'flex' }}>
              <div style={{ width: 80, overflow: 'hidden', fontSize: 10 }}>
                {
                  this.category.member.profileImageUrl
                    ? <img alt={this.category.member.fullName} src={this.category.member.profileImageUrl} style={{ width: 80, height: 80, objectFit: 'cover', objectPosition: 'top center', backgroundColor: '#ccc' }}/>
                    : null
                }
              </div>
              <div style={{ flex: 1, textAlign: 'center' }}>
                <div style={{ fontSize: 22 }}>{this.category.member.fullName}</div>
                <div style={{ fontSize: 20 }}>{this.category.business.name}</div>
                <div style={{ fontSize: 20 }}>{this.category.name}</div>
              </div>
              <div style={{ width: 80, overflow: 'hidden', fontSize: 10 }}>
                {
                  this.category.logoUrl
                    ? <img alt={this.category.business.name} src={this.category.logoUrl} style={{ width: 80, height: 80, objectFit: 'cover', objectPosition: 'top center', backgroundColor: '#ccc' }}/>
                    : null
                }
              </div>
            </div>
            {
              this.category.member.videoData
                ? <div>
                  <VideoPlayer videoData={this.category.member.videoData}/>
                </div>
                : null
            }
            <div>
              <Button
                color="primary"
                variant="contained"
                fullWidth
                onClick={() => {
                  this.showTipForm = true
                  window.scrollTo(0, 0)
                }}
              >
                Contact Me
              </Button>
            </div>
            {
              this.category.business.description
                ? <div style={{ fontSize: 20, marginTop: 20 }}>
                  {this.category.business.description}
                </div>
                : null
            }
          </Paper>
          {
            this.showTipForm
              ? <TipFormDialog
                onExited={() => this.showTipForm = false}
                category={this.category!}
                onSubmitted={() => this.history.goBack()}
              />
              : null
          }
        </>
  }
}
