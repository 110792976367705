import ReactGA from 'react-ga'
import Config from "./Config"
import * as React from "react"
import { RouteComponentProps, withRouter } from "react-router"

if (Config.GOOGLE_ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(Config.GOOGLE_ANALYTICS_TRACKING_ID as string)
}

class GAListener extends React.Component<RouteComponentProps> {
  componentDidMount () {
    this.sendPageView(this.props.history.location)
    this.props.history.listen(this.sendPageView)
  }

  sendPageView (location: any) {
    if (Config.GOOGLE_ANALYTICS_TRACKING_ID) {
      ReactGA.set({ page: location.pathname })
      ReactGA.pageview(location.pathname)
    }
  }

  render () {
    return this.props.children
  }
}

export default withRouter(GAListener)