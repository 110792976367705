import { observable } from "mobx"
import FormState from "../common/FormState"
import ErrorBag from "../common/ErrorBag"

export default class FormHelper<P> {
  @observable
  public readonly formState: FormState<P>
  @observable
  public readonly formErrors: ErrorBag

  constructor (formState: FormState<P>, formErrors: ErrorBag) {
    this.formState = formState
    this.formErrors = formErrors
  }

  getFormState = () => {
    return this.formState
  }

  toObject () {
    return this.formState.toObject()
  }

  applyProps = (fieldName: string): any => {
    let props: any = {}

    if (this.formErrors.hasErrors(fieldName)) {
      props.error = true
      props.helperText = this.formErrors.getError(fieldName)
    }

    props.value = this.formState.get(fieldName)
    props.onChange = this.formState.onChange

    return props
  }
}
