import * as _ from 'lodash'
import { action, observable, toJS } from "mobx"

interface ErrorList {
  [field: string]: Array<string>,
}

export default class ErrorBag {
  @observable
  private errors: ErrorList = {}

  constructor (private convertFieldsToCamelCase: boolean = true) {
  }

  hasErrors = (field?: string) => {
    return field ? this.errors[field] && this.errors[field].length : _.flatMap(this.errors).length
  }

  getError = (field: string) => {
    return this.hasErrors(field)
      ? this.errors[field].join(', ')
      : ''
  }

  getErrors = (): string[] => _.flatMap(this.errors, (e: any) => e)

  @action
  clearErrors = (): ErrorBag => {
    this.errors = {}
    return this
  }

  @action
  addError = (field: string, message: string): ErrorBag => {
    field = field.split('.').map(s => this.convertFieldsToCamelCase ? _.camelCase(s) : s).join('.')

    this.errors = { ...this.errors, [field]: [...(this.errors[field] || []), message] }

    return this
  }

  @action
  addErrors = (errors: ErrorList): ErrorBag => {
    for (let field in errors) {
      if (errors.hasOwnProperty(field)) {
        errors[field].forEach((v: string) => {
          this.addError(field, v)
        })
      }
    }

    return this
  }

  getErrorList = () => {
    return toJS(this.errors)
  }
}
