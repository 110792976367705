import React from 'react'
import { observer } from 'mobx-react'
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core'
import { safeNull } from '../common/Util'
import AuthStore from '../stores/AuthStore'

@observer
export default class RevokedView extends React.Component {
  render () {
    return <Card>
      <CardHeader title="Access Revoked"/>
      <CardContent>
        <Typography>
          Your access to the LeTip Extended Network has been revoked by {safeNull(() => AuthStore.getAuthUser()!.invitingMember.fullName)}. Please contact {safeNull(() => AuthStore.getAuthUser()!.invitingMember.fullName)} for more information.
        </Typography>
      </CardContent>
    </Card>
  }
}
