import React from 'react'
import { Button, IconButton } from '@material-ui/core'
import { observer } from 'mobx-react'
import { observable } from 'mobx'
import { route } from '../routes/routes'
import { Routes } from '../routes/AppRoutes'
import { Redirect } from 'react-router'
import AppStateStore from '../stores/AppStateStore'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import { createBrowserHistory } from 'history'

@observer
export default class IntroVideoView extends React.Component {
  @observable private shouldRedirect = false

  private history = createBrowserHistory()

  componentDidMount (): void {
    AppStateStore.headerBackButton = <IconButton onClick={() => this.history.goBack()}>
      <ArrowBackIcon style={{ color: '#fff' }}/>
    </IconButton>

    localStorage.setItem('hasSeenIntroVideo', '1')
  }

  componentWillUnmount (): void {
    AppStateStore.headerBackButton = undefined
  }

  render () {
    return this.shouldRedirect
      ? <Redirect to={route(Routes.index)}/>
      : <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <iframe
            style={{ flex: 1 }}
            title="Video"
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/aDm_7lcmpX8?autoplay=1"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen/>
        </div>
        <div>
          <Button onClick={() => this.shouldRedirect = true} variant="contained" color="primary" fullWidth>Close Video</Button>
        </div>
      </div>
  }
}
