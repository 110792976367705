import * as React from "react"
import { observer } from "mobx-react"
import { VideoData } from '../models/Category'

@observer
export default class VideoPlayer extends React.Component<{
  videoData: VideoData,
}> {

  render (): React.ReactNode {
    if (!this.props.videoData) {
      return null
    }

    if (this.props.videoData.type === 'youtube') {
      return <iframe
        title="Video"
        width="100%"
        height="320"
        src={`https://www.youtube.com/embed/${this.props.videoData.id}`}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen/>
    } else if (this.props.videoData.type === 'vimeo') {
      return <iframe
        title="Video"
        src={`https://player.vimeo.com/video/${this.props.videoData.id}`}
        width="100%"
        height="320"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen/>
    } else {
      return null
    }
  }
}
