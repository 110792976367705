import { compileRoutes } from './routes'
import HomeView from '../views/HomeView'
import RevokedView from '../views/RevokedView'
import CategoryView from '../views/CategoryView'
import IntroVideoView from '../views/IntroVideoView'
import * as React from 'react'

export const Routes = {
  landing: '/l/:authToken',
  index: '/',
  category: '/category/:id',
  revoked: '/revoked',
  introVideo: '/intro-video',
}

compileRoutes(Routes)

export const AppRoutes: {
  path: string
  component: React.ComponentType<any>
  secure?: boolean
  sensitive?: boolean
  strict?: boolean
}[] = [
  {
    path: Routes.index,
    component: HomeView,
    secure: true,
  },
  {
    path: Routes.category,
    component: CategoryView,
    secure: true,
  },
  {
    path: Routes.revoked,
    component: RevokedView,
  },
  {
    path: Routes.introVideo,
    component: IntroVideoView,
  },
].map(r => Object.assign({
  exact: true,
  sensitive: true,
  strict: true,
}, r))
