import React from 'react'
import './App.scss'
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Link, Typography } from '@material-ui/core'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import GAListener from './common/GAListener'
import { AppRoutes, Routes } from './routes/AppRoutes'
import NotFoundView from './views/NotFoundView'
import { observer } from 'mobx-react'
import AuthStore from './stores/AuthStore'
import { autorun, observable } from 'mobx'
import ApiClient, { apiRoute, ApiRoutes } from './api/ApiClient'
import AuthUser from './models/AuthUser'
import { extractErrorMessage } from './common/Util'
import AppVersion from './components/AppVersion'
import { Alert, AlertTitle } from '@material-ui/lab'
import storage from 'local-storage-fallback'
import moment from 'moment-timezone'
import LandingView from './views/LandingView'
import AppStateStore from './stores/AppStateStore'
import { PrivateRoute } from './components/PrivateRoute'
import { globalEventBus } from './common/EventBus'
import { route } from './routes/routes'

@observer
export default class App extends React.Component {
  @observable private profileAuthToken?: string
  @observable private error?: string
  @observable private loading = false
  @observable private showInstallModal = false
  @observable private showInstallLaterModal = false
  @observable private showInstallButton = false
  @observable private loadNewVersion?: any

  private deferredInstallPrompt?: any

  componentDidMount (): void {
    AuthStore.restoreAuthToken()
    this.profileAuthToken = AuthStore.getAuthToken()
    this.fetchProfile()

    globalEventBus.on('newVersionAvailable', this.onNewVersionAvailable)

    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      this.deferredInstallPrompt = e

      const installed: any = storage.getItem('appInstallation')
      if (!installed || installed.installed) {
        this.showInstallModal = true
      }
    })

    autorun(() => {
      if (this.profileAuthToken !== AuthStore.getAuthToken()) {
        this.fetchProfile()
      }
    })
  }

  componentWillUnmount (): void {
    globalEventBus.remove(this.onNewVersionAvailable)
  }

  private onNewVersionAvailable = (e: {reload: () => void}) => {
    this.loadNewVersion = e.reload
  }

  private fetchProfile = () => {
    this.loading = true
    this.error = undefined
    AuthStore.clearAuthUser()

    ApiClient.getInstance()
      .get(apiRoute(ApiRoutes.profile))
      .then(response => {
        this.profileAuthToken = AuthStore.getAuthToken()
        AuthStore.setAuthUser(new AuthUser(response.data.name, response.data.status, response.data.invitingMember))

        const el = document.querySelector('#pwa-manifest')

        if (el) {
          const url = apiRoute('manifest.json', { l: this.profileAuthToken })
          el.setAttribute('href', url)
        }
      })
      .catch(error => {
        if (error.response && error.response.status === 401) {
          this.error = 'Invalid link'
          return
        }

        this.error = extractErrorMessage(error.response)
      })
      .then(() => {
        this.loading = false
      })
  }

  private presentInstallPrompt = () => {
    this.showInstallModal = false
    this.deferredInstallPrompt.prompt()
    this.deferredInstallPrompt.userChoice.then(result => {
      if (result.outcome === 'accepted') {
        storage.setItem('appInstallation', JSON.stringify({
          installed: true,
          date: moment().toISOString(),
        }))
      } else {
        storage.setItem('appInstallation', JSON.stringify({
          installed: false,
          date: moment().toISOString(),
        }))

        this.showInstallLaterModal = true
        this.showInstallButton = true
      }
    })

    this.deferredInstallPrompt = undefined
  }

  private renderContent = (authUser: AuthUser | undefined) => {
    // if (authUser && authUser.status === AuthUserStatus.Revoked) {
    //   return <Redirect to={route(Routes.revoked)}/>
    // }

    return <>
      <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
        <div className="app-header">
          <div className="header-left-icon">
            {AppStateStore.headerBackButton}
          </div>
          <div className="app-logo">
            <img src="/img/letip-logo.png" alt="LeTip"/>
          </div>
          <div className="header-right-icon">
          </div>
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <Box my={4} style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Switch>
              <Route
                path="/l/:authToken"
                component={LandingView}
                exact={true}
                strict={true}
                sensitive={true}
              />
              {
                AuthStore.getAuthUser()
                  ? AppRoutes.map(r => {
                    const { secure, ...rest } = r
                    return (
                      secure
                        ? <PrivateRoute
                          key={r.path}
                          {...rest}
                        />
                        : <Route
                          key={r.path}
                          {...rest}
                        />
                    )
                  })
                  : <Route>
                    Not logged in
                  </Route>
              }
              <Route component={NotFoundView}/>
            </Switch>
          </Box>
        </div>
      </Container>
    </>
  }

  private renderError = () => {
    return <Alert severity="error">
      <AlertTitle>Error</AlertTitle>
      {this.error}
    </Alert>
  }

  render () {
    const authUser = AuthStore.getAuthUser()
    return <Router>
      <GAListener>
        <div className="app-container">
          {
            this.loadNewVersion
              ? <Alert severity="info">
                <AlertTitle>Update Available</AlertTitle>
                A new version of this application is available. Please click the button to install the latest version and continue using the application.
                <br/>
                <div style={{ textAlign: 'center', marginTop: 10 }}>
                  <Button
                    variant="contained"
                    onClick={this.loadNewVersion}
                    color="primary"
                  >Install Update Now</Button>
                </div>
              </Alert>
              : null
          }
          <div className="app-content">
            {
              this.loading
                ? <AppLoader/>
                : this.error
                ? this.renderError()
                : this.renderContent(authUser)
            }
          </div>
          <Container maxWidth="sm">
            <Typography align="center" className="app-copyright">
              <div style={{ flex: 1, paddingRight: 20, textAlign: 'left' }}>
                {'Copyright © '}
                <Link color="inherit" target="_blank" href="https://letip.com/">
                  LeTip International
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
              </div>
            </Typography>
          </Container>
          {
            (authUser && this.showInstallButton && this.deferredInstallPrompt)
              ? <div style={{ marginTop: 20 }}><Button fullWidth variant="contained" color="primary" onClick={this.presentInstallPrompt}>Install Application To Home Screen</Button></div>
              : null
          }
          <AppVersion/>
        </div>

        <Dialog
          open={this.showInstallModal}
          onClose={() => this.showInstallModal = false}
        >
          <DialogTitle>Add to Home Screen</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can install the LeTip Extended Network application to your device for easy access in the future. You will be prompted to install the application to your home screen if you proceed.
              <br/><br/>
              Would you like to install LeTipExtended Network to your home screen?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => {
              this.showInstallModal = false
              this.showInstallLaterModal = true
              this.showInstallButton = true
            }} color="secondary">
              No
            </Button>
            <Button onClick={() => this.presentInstallPrompt()} color="primary" autoFocus>
              Yes
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={this.showInstallLaterModal}
          onClose={() => this.showInstallLaterModal = false}
        >
          <DialogTitle>Install Later</DialogTitle>
          <DialogContent>
            <DialogContentText>
              You can always install the application to your home screen later using the button at the bottom of this page.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => this.showInstallLaterModal = false} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </GAListener>
    </Router>
  }
}

class AppLoader extends React.Component {
  componentDidMount (): void {
    document.body.classList.add('loader')
  }

  componentWillUnmount (): void {
    document.body.classList.remove('loader')
  }

  render () {
    return <div className="app-loader">
      <div>
        <img src="/img/letip-logo.png" alt="LeTip"/>
      </div>
      <CircularProgress/>
    </div>
  }
}
